<template>
	<q-item tag="label" class="q-my-sm">
		<q-item-section>
			<q-item-label>{{ field.label }}</q-item-label>
		</q-item-section>
		<q-item-section avatar>
			<q-toggle v-model="value" />
		</q-item-section>
	</q-item>
</template>

<script>
import { mapActions } from 'vuex'
export default {
	name: 'MergeField',
	props: {
		field: Object,
		index: Number,
	},
	computed: {
		value: {
			get() {
				return this.field.merge_field_enabled
			},
			set(val) {
				this.updateMergeField({ index: this.index, val })
			},
		},
	},
	methods: {
		...mapActions(['updateMergeField']),
	},
}
</script>

<style>
</style>
