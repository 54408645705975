<template>
	<div
		class="flex column"
		v-if="customer_group_permissions.merge_fields"
	>
		<div>
			<h1 class="setting-title">
				Merge fields
			</h1>
			<div
				class="settings-wrapper"
				v-for="(field, index) in merge_fields"
				:key="field.id"
			>
				<merge-field
					:field="field"
					:index="index"
				/>
			</div>
		</div>
		<save-button @save="save" />
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SaveButton from '@/components/datasets/utils/SaveButton.vue'
import MergeField from '@/components/datasets/edit/merge_fields/MergeField.vue'

export default {
	name: 'MergeFields',
	components: {
		SaveButton,
		MergeField,
	},
	computed: {
		...mapGetters(['customer_group_permissions', 'merge_fields']),
	},
	methods: {
		...mapActions(['saveMergeFields', 'fetchUser']),
		async save() {
			const response = await this.saveMergeFields({
				cg_id: this.$route.params.id,
				data: this.merge_fields,
			})
			if (response.error) {
				this.$q.notify({
					timeout: 6700,
					message: response.error_message,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
				return
			}
			this.$q.notify(response)
			await this.fetchUser()
		},
	},
}
</script>
